<template>
<v-row>
  <v-col cols="12" sm="6">
    <lookup-dominio label="* Tipo Item"
                    :hidden-clear="true"
                    hint="Selecione o tipo do Contratado"
                    v-model="value.idTipoItem"
                    :type="lookups.tipoItem"
                    :rules="[
                      this.$validators.notNullOrUndefined
                    ]"/>
  </v-col>   
  <v-col cols="12" md="6">
    <v-text-field label="* Código"
                  dense
                  :rules="[
                      this.$validators.number.required,
                        v => this.$validators.string.greaterThanOrEquals(v, 1),
                        v => this.$validators.string.lessThanOrEquals(v, 20)
                      ]"
                  v-model.trim="value.cdItem"/>
  </v-col>
  <v-col cols="12" sm="12">
    <v-textarea 
                dense
                rows="3"
                label="* Nome"
                :rules="[
                      this.$validators.string.required,
                          v => this.$validators.string.greaterThanOrEquals(v, 1),
                          v => this.$validators.string.lessThanOrEquals(v, 4000)
                  ]"
                v-model.trim="value.descricao"/>
  </v-col>
</v-row>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";

export default {
  name: "ItemForm",
  components: {LookupDominio},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      lookups: {
        tipoItem: ItemDeDominio.TIPO_ITEM 
      },
    }
  },
}
</script>

<style scoped>

</style>