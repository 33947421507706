var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('lookup-dominio',{attrs:{"label":"* Tipo Item","hidden-clear":true,"hint":"Selecione o tipo do Contratado","type":_vm.lookups.tipoItem,"rules":[
                      this.$validators.notNullOrUndefined
                    ]},model:{value:(_vm.value.idTipoItem),callback:function ($$v) {_vm.$set(_vm.value, "idTipoItem", $$v)},expression:"value.idTipoItem"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"* Código","dense":"","rules":[
                      this.$validators.number.required,
                        function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                        function (v) { return this$1.$validators.string.lessThanOrEquals(v, 20); }
                      ]},model:{value:(_vm.value.cdItem),callback:function ($$v) {_vm.$set(_vm.value, "cdItem", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.cdItem"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-textarea',{attrs:{"dense":"","rows":"3","label":"* Nome","rules":[
                      this.$validators.string.required,
                          function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                          function (v) { return this$1.$validators.string.lessThanOrEquals(v, 4000); }
                  ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.descricao"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }